@font-face {
  font-family: 'Midnight Signature';
  src: url('MidnightSignature-rg3gK.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
html, body {
  width: 100%;
  height: 100%;
  min-height: 100%;
  font-family: 'Montserrat', sans-serif;
  margin: 0;
  padding: 0;
}

body {
  background-color: #92a8d2;
  font-size: 9px;
  display: flex;
  flex-direction: column;
  align-items: center; /* Adicionado */
  justify-content: center; /* Adicionado */
}

h1 {
  margin: 5px 0;
  font-size: 26px;
  color: #666;
}

h2 {
  margin: 3px 0 0;
  font-size: 16px;
  font-weight: normal;
  color: #666;
}

.head-text {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 90px;
  font-size: 30px;
  font-weight: 100;
  color: black;
  font-family: 'Midnight Signature', cursive;
  letter-spacing: 3px;
}

.menu {
  background-color: #8193b4;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  color: #b3b3b3;
  height: 60px;
  z-index: 1000;
  display: flex;
  justify-content: space-around;
  align-items: center;
  transition: opacity 0.5s ease, visibility 0.5s ease; /* Transições suaves */
}

.menu.hidden {
  opacity: 0;
  visibility: hidden;
}

.menu.visible {
  opacity: 1;
  visibility: visible;
}

.menu .list {
  display: flex;
  justify-content: space-around;
  width: 100%;
  max-width: 400px;
}

.menu .list .item {
  display: flex;
  padding: 0 10px;
  border-right: 1px solid #758195;
  flex-direction: column;
  align-items: center;
  font-size: 0.8rem;
  color: #eaeff8;
}


/* Mídia query para telas com largura menor que 350px */
@media (max-width: 362px) {
  .menu .list .item {
    padding: 0 2px;
    border-right: none;

  }
}

.menu .list .item:last-child {
  border-right: none;
}


.animated-gift {
  display: inline-block;
  animation: tilt 1.5s infinite alternate ease-in-out;
}

@keyframes tilt {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(15deg);
  }
  50% {
    transform: rotate(0deg);
  }
  75% {
    transform: rotate(-15deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.menu .list .item a {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  color: inherit;
}

.menu .list .item a.active {
  color: #ffffff;
}

.menu .list .item span {
  display: block;
  text-align: center;
}

.menu .list .item .material-symbols-outlined {
  font-size: 28px;
  transition: transform 0.4s ease;
}

.menu .list .item a.active .material-symbols-outlined {
  transform: scale(1.3);
}

.menu .list .item span + span {
  font-size: 12px;
}


.ticket-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  width: 100%;
  margin-top: 20px;
  position: relative; /* Adiciona position relative para compatibilidade com transições */
}



.ticket {
  width: 100%;
  max-width: 340px;
  min-width: 100%; /* Default min-width */
  color: #b3b3b3;
  z-index: 0;
  background: white;
  border-radius: 10px;
}

@media (min-width: 350px) {
  .ticket {
    min-width: 340px; /* Dynamic min-width for larger resolutions */
  }
}

.ticket-header {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 20px;
  border-radius: 10px 10px 0 0;
  color: white;
  background-color: #b4c9e7;
}

.head-logo {
  background-image: url("img/logo.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 56%;
  width: 100%;
  height: 90px;
  font-size: 20px;
  font-weight: 700;
  font-family: 'Midnight Signature', cursive;
}

.ticket-body {
  position: relative;
  border-bottom: 1px dashed #d9d9d9;
  background-color: white;
}

.ticket-body:before, .ticket-body:after {
  content: '';
  position: absolute;
  top: 100%;
  width: 16px;
  height: 16px;
  border-radius: 8px;
  background-color: #92a8d2;
}

.ticket-body:before {
  right: 100%;
  transform: translate(8px, -8px);
}

.ticket-body:after {
  left: 100%;
  transform: translate(-8px, -8px);
}

.locations, .info, .flight, .event, .event-detail {
  display: flex;
  align-items: center;
}

.body-info {
  padding: 20px 35px;
}

.locations {
  padding: 20px 35px;
  border-bottom: 1px solid #d9d9d9;
}

.loc-depart, .loc-direction, .loc-arrive {
  flex-grow: 1;
  flex-shrink: 0;
  text-transform: uppercase;
  text-align: center;
}

.guest-info {
  padding-left: 35px;
  padding-right: 35px;
}

.arrow {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 2px;
  background-color: #b4c9e7;
}

.arrow:before, .arrow:after {
  content: '';
  position: absolute;
  width: 15px;
  height: 2px;
  background-color: #b4c9e7;
}

.arrow:before {
  transform: rotate(45deg);
  transform-origin: 12px -5px;
}

.arrow:after {
  transform: rotate(-45deg);
  transform-origin: 12px 7px;
}

.info {
  margin-bottom: 15px;
  justify-content: space-between;
}

.info-name, .info-seat {
  text-transform: uppercase;
}

.info-name h2, .info-seat h2 {
  text-transform: none;
}

.info-seat {
  text-align: left;
}

.event {
  justify-content: space-between;
  text-transform: uppercase;
}

.no-link-style {
  color: inherit;
  text-decoration: none;
}

.event-info h2 {
  text-transform: none;
}

.event-detail {
  margin-top: 15px;
  justify-content: space-between;
  text-transform: uppercase;
}

.event-detail h2 {
  text-transform: none;
}

.flight {
  justify-content: space-between;
  text-transform: uppercase;
}

.flight-info h2, .flight-depart-date h2, .flight-depart-time h2 {
  text-transform: none;
}

.ticket-bottom {
  border-radius: 0 0 10px 10px;
  background-color: white;
}

.bottom-info {
  padding: 20px 35px;
}

.depart {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  text-transform: uppercase;
}

.depart h2 {
  text-transform: none;
  font-size: 12px;
}

.depart-barcode {
  width: 100%;
  height: 45px;
  background: linear-gradient(to right, #000 0%, #000 20%, #fff 20%, #fff 40%, #000 40%, #000 50%, #fff 50%, #fff 70%, #000 70%, #000 90%, #fff 90%, #fff 100%);
  background-size: 7.5% 100%;
}
/* Slide-in from the right */
.slide-enter {
  transform: translateX(100%);
  opacity: 0;
}
.slide-enter-active {
  transform: translateX(0);
  opacity: 1;
  transition: transform 500ms ease-in-out, opacity 500ms ease-in-out;
}

/* Slide-out to the left */
.slide-exit {
  transform: translateX(0);
  opacity: 1;
}
.slide-exit-active {
  transform: translateX(-100%);
  opacity: 0;
  transition: transform 500ms ease-in-out, opacity 500ms ease-in-out;
}

.fade-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.dress-code {
  padding: 25px;
  line-height: 1.9;
}

.dress-code h2 {
  margin-bottom: 20px;
  font-size: 24px;
  text-align: center;
}

.dress-code p {
  margin: 0px 0;
  color: #666;
  font-weight: 700;

}

.dress-code h2 {
margin: 3px 0 0;
font-size: 16px;
font-weight: normal;
color: #666;
}

.dress-code ul {
  list-style-type: none; /* Remove o estilo de lista padrão */
  padding-left: 0px;
  margin: 10px 0;
}

.dress-code ul li {
  position: relative; /* Permite posicionar a seta */
  font-size: 10px;
}

.dress-code ul li::before {
  content: ''; /* Define o conteúdo como uma seta */
  position: absolute;
  left: -15px; /* Ajusta a posição da seta */
  color: #000; /* Define a cor da seta */
}

.location {
  padding: 20px;
  line-height: 1.6;
  text-align: center;
}

.location h2 {

    margin: 3px 0 0;
    font-size: 16px;
    font-weight: normal;
    color: #666;
  text-transform: uppercase; /* Transforma o texto em maiúsculas */


  margin-bottom: 20px;
  text-align: center;
}

.location p {
  margin: 10px 0;
}

.button-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Estilo dos botões de ação */
.action-button {
  display: inline-block;
  padding: 10px 20px;
  margin-top: 10px;
  margin-right: 10px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  text-decoration: none;
  width: 250px; /* Define um padrão de tamanho */
  text-align: center;
}

.action-button:hover {
  background-color: #45a049;
}

/* Estilo específico para o botão do Waze */
.waze-button {
  background-color: #2D9CDB;
}

.waze-button:hover {
  background-color: #2A8ACB;
}

/* Estilo específico para o botão do Google Calendar */
.calendar-button {
  background-color: #EA4335;
}

.calendar-button:hover {
  background-color: #D93E30;
}

/* Estilo específico para o botão do Airbnb */
.airbnb-button {
  background-color: #FF5A5F;
}

.airbnb-button:hover {
  background-color: #E5494F;
}







.modal {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  width: 80%;
  max-width: 500px;
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.modal-guest-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  font-size: 14px;
}

.confirm-button.green {
  background-color: #4CAF50;
}

.confirm-button.yellow {
  background-color: #FFC107;
}

.confirm-button.red {
  background-color: #F44336;
}




.confirm-button-container {
  display: flex;
  justify-content: center; /* Centraliza o botão */
  margin: 10px 0;
}

.confirm-button {
  background-color: #92a8d2; /* Cor principal para o botão */
  color: white;
  display: inline-block;
  padding: 10px 20px;
  margin-top: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  text-decoration: none;
  width: 250px; /* Define um padrão de tamanho */
  text-align: center;
  transition: background-color 0.3s ease; /* Transição suave */
}

.confirm-button:hover {
  background-color: #7698bd; /* Cor ao passar o mouse */
}


.modal-guest-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 15px 0;
  transition: opacity 0.5s ease, height 0.5s ease;
}

.modal-guest-item.hidden {
  opacity: 0;
  height: 0;
  overflow: hidden;
}




.confirmation-buttons {
  display: flex;
  gap: 10px;
}

.confirm-icon {
  color: green;
  cursor: pointer;
}

.maybe-icon {
  color: orange;
  cursor: pointer;
}

.cancel-icon {
  color: red;
  cursor: pointer;
}

.status-icon {
  margin-left: 10px;
}

.confirmed {
  color: green;
}

.pending {
  color: orange;
}

.cancelled {
  color: red;
}
.modal-guest-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f9f9f9;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
}

.modal-guest-item h3 {
  margin: 0;
  font-size: 16px;
  color: #939393;
  flex-grow: 1;
}

.confirmation-buttons {
  display: flex;
  align-items: center;
  gap: 8px;
}

.response-button {
  padding: 8px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  font-size: 16px;
  color: white;
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
}

.response-button {
  background-color: #ccc; /* Default to gray */
  color: #fff;
}

.response-button:hover {
  opacity: 0.9;
}

.response-button.yes.active {
  background-color: #28a745; /* Green */
}

.response-button.maybe.active {
  background-color: #FFC107; /* Yellow */
}

.response-button.no.active {
  background-color: #F44336; /* Red */
}

.material-symbols-outlined {
  font-size: 24px; /* Adjust the size as necessary */
}

.qr-code-container {
  text-align: center;
  padding: 20px;
}

.qr-code-container img {
  margin: 20px 0;
  max-width: 100%;
  height: auto;
}


.carousel {
  position: relative;
  width: 100%;
  height: 350px; /* Ajuste a altura conforme necessário */
  overflow: hidden;
}

.carousel-image {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  position: absolute;
  top: 0;
  left: 0;
  transition: opacity 1.3s ease-in-out;
  opacity: 0;
  z-index: 0;
}

.carousel-image.active {
  opacity: 1;
  z-index: 1;
  animation: zoomIn 5s ease-in-out;
}

@keyframes zoomIn {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.1);
  }
}
